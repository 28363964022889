import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const WidgetLoader = () => {
  const location = useLocation();

  useEffect(() => {
    const injectScripts = () => {
      const hashParams = new URLSearchParams(
        window.location.hash.split("?")[1]
      );
      const userSessionID = hashParams.get("id");
      const existingScripts = document.querySelectorAll(
        "script[data-widget-script]"
      );
      existingScripts.forEach((script) => script.remove());
      if (location.pathname === "/widget" && userSessionID) {
        const script = document.createElement("script");
        script.type = "module";
        script.src = `/widget/chat-widget.iife.js?id=${userSessionID}`;
        script.async = true;
        script.dataset.widgetScript = "true";
        script.onload = () => {
          if (window.ChatWidget) {
            window.ChatWidget.mount(".chat-widget-container");
            window.ChatWidget.mountToggleButton(
              ".button",
              ".chat-widget-container"
            );
          } else {
            console.error("ChatWidget is not defined after script load.");
          }
        };
        document.head.appendChild(script);
      }
    };

    injectScripts();

    return () => {
      const existingScripts = document.querySelectorAll(
        "script[data-widget-script]"
      );
      existingScripts.forEach((script) => script.remove());

      const widgetContainer = document.querySelector(".chat-widget-container");
      const buttonContainer = document.querySelector(".button");
      if (widgetContainer) {
        widgetContainer.remove();
      }
      if (buttonContainer) {
        buttonContainer.remove();
      }

      if (window.ChatWidget) {
        window.ChatWidget.unmount(".chat-widget-container");
      }
    };
  }, [location]);

  return <div id="root" style={{ backgroundColor: "#f7f8fa" }}></div>;
};

export default WidgetLoader;
